import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useForm } from "react-hook-form";
import contact from "./../util/contact";
import MenuItem from '@material-ui/core/MenuItem';
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import Checkbox from '@material-ui/core/Checkbox';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createResponses } from './../util/db';
const useStyles = makeStyles((theme) => ({
  image:{
    marginBottom: "-5px",
    [theme.breakpoints.down("sm")]: {
display: "none"
    }
  },
  root1: { 
    paddingLeft: "0px",
   },
   form1:{
    padding: '30px 0px 30px 30px',
    [theme.breakpoints.down("sm")]: {
      padding: '30px 30px 30px 30px',
          }
   }
}));
function Contact(props) {
  const classes = useStyles();
  const [pending, setPending] = useState(false);
  const [organizationtype, setOrganizationtype] = useState('');
  const [formAlert, setFormAlert] = useState(null);
  const { handleSubmit, register, errors, reset } = useForm();
  const [checked, setChecked] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const onSubmit = async (data) => {
    try{
    setPending(true);
    if(organizationtype === ''){
      throw new SyntaxError("Please select organization type"); 
    }
    const formresponse = {
      name: data.name,
      email: data.email,
      organization: data.organization_name,
      organizationtype: organizationtype,
      phone: data.phone,
      designation: data.designation,
      country: data.country,
    }
    await createResponses(formresponse);
    toast.success('Your form has been Submitted successfully', {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
      setPending(false);
      setSubmitted(true);
    }catch(error){
      toast.error( error.message , {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
        setPending(false);
    }

  };


  return (
    <>
    <ToastContainer
position="bottom-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
      {formAlert && (
        <Box mb={3}>
          <Alert severity={formAlert.type}>{formAlert.message}</Alert>
        </Box>
      )}
       <Grid container={true} spacing={0} >
        
       <Grid item={true} xs={12} md={6}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container={true} spacing={2} className={classes.form1}>
        <Grid item={true} xs={12} > 
        <Typography variant="h5" gutterBottom>
                {props.form_heading}
        </Typography>
        </Grid>
            <Grid item={true} xs={12} >
              <TextField
                variant="standard"
                type="text"
                label="Name"
                name="name"
                error={errors.name ? true : false}
                helperText={errors.name && errors.name.message}
                fullWidth={true}
                inputRef={register({
                  required: "Please enter your name",
                })}
              />
            </Grid>
            <Grid item={true} xs={12} >
              <TextField
                variant="standard"
                type="text"
                label="Name of the Organization/Institution"
                name="organization_name"
                error={errors.organization_name ? true : false}
                helperText={errors.organization_name && errors.organization_name.message}
                fullWidth={true}
                inputRef={register({
                  required: "Please enter your Name of the Organization/Institution",
                })}
              />
            </Grid>
            <Grid item={true} xs={12} >
              <TextField
                variant="standard"
                type="text"
                label="Organization Type"
                name="organization_type"
                select
                value={organizationtype}
                error={errors.organization_type ? true : false}
                helperText={errors.organization_type && errors.organization_type.message}
                fullWidth={true}
                onChange={(event) => {setOrganizationtype(event.target.value);}}
              >
                     {props.orgtype.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
              </TextField>
            </Grid>
            <Grid item={true} xs={12} >
              <TextField
                variant="standard"
                type="text"
                label="Designation"
                name="designation"
                error={errors.designation ? true : false}
                helperText={errors.designation && errors.designation.message}
                fullWidth={true}
                inputRef={register({
                  required: "Please enter your Designation",
                })}
              />
            </Grid>


          <Grid item={true} xs={12}>
            <TextField
              variant="standard"
              type="email"
              label="Email"
              name="email"
              error={errors.email ? true : false}
              helperText={errors.email && errors.email.message}
              fullWidth={true}
              inputRef={register({
                required: "Please enter your email",
              })}
            />
          </Grid>
          <Grid item={true} xs={12} >
              <TextField
                variant="standard"
                type="phone"
                label="Phone"
                name="phone"
                onInput = {(e) =>{e.target.value =  e.target.value.replace(/[^0-9]/g, '').slice(0,10);}}
                error={errors.phone ? true : false}
                helperText={errors.phone && errors.phone.message}
                fullWidth={true}
                inputRef={register({
                  required: "Please enter your Phone Number",
                })}
              />
            </Grid>
            <Grid item={true} xs={12} >
              <TextField
                variant="standard"
                type="text"
                label="Country"
                name="country"
                error={errors.country ? true : false}
                helperText={errors.country && errors.country.message}
                fullWidth={true}
                inputRef={register({
                  required: "Please enter your Country",
                })}
              />
            </Grid>
            <Grid item={true} xs={12} style={{fontSize:'10px'}}>
            <Checkbox
                  classes={{ root: classes.root1 }}
        onChange={(e) => setChecked(e.target.checked)}
        icon={<CheckCircleOutlineRoundedIcon />}
        checkedIcon={<CheckCircleRoundedIcon  color={'primary'} />}
      />   {"I have read HCL Technologies’ Privacy Policy and agree to the terms and conditions*"}
      <br />


<br />
{"By providing your contact information and clicking 'submit', you authorize HCLTech to store your contact details and contact you with information on case studies, whitepapers, events, webinars, newsletters, announcements and other relevant updates."}
</Grid>
          <Grid item={true} contentAlign='center' xs={12}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              style={{textTransform: 'none', textAlign: 'center', }}
              disabled={pending || !checked || submitted}
            >
              {!pending && <span>{props.buttonText}</span>}

              {pending && <CircularProgress size={28} />}
            </Button>
          </Grid>
        </Grid>
      </form>
      </Grid>
      <Grid item={true} xs={12} md={6} className={classes.image} >
      <img src={require("./../assets/images/form_section.png")} style={{width: "100%"}}/>
        </Grid>
       
      </Grid>
    </>
  );
}

export default Contact;
