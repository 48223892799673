
  import {
    getFirestore,
    collection,
    addDoc,
    serverTimestamp,
  } from "firebase/firestore";
  import { firebaseApp } from "./firebase";

  
  const db = getFirestore(firebaseApp);


export function createResponses(data) {
    return addDoc(collection(db, "responses"), { ...data, date: serverTimestamp()});
  }