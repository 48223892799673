import React, { useState, useEffect } from "react";
import Meta from "./../components/Meta";
import HeroSection from "./../components/HeroSection";
import FeaturesSection from "./../components/FeaturesSection";
import ContentCardsSection from "./../components/ContentCardsSection";
import CardsSection from "./../components/Cardsection";
import ContactSection from "./../components/ContactSection";
import Navbar from "./../components/Navbar";

const orgtype = [
  {
    value: 'Enterprise',
    label: 'Enterprise',
  },
  {
    value: 'NGO',
    label: 'NGO',
  },
  {
    value: 'Vocational_institute',
    label: 'Vocational institute',
  },
  {
    value: 'Society',
    label: 'Society',
  },
];

const items = [
  {
    title1: 'Tech and Engineering = Equity',
    title2: 'Finance = Independence',
    text1: 'Progress is the key to success in the tech industry, and we are dedicated to providing the resources and support needed to achieve it.',
    text2: 'Understanding finance helps individuals to plan and execute any work for sustenance and not just for progress',
    image1: require("./../assets/images/section3_img1.png"),
    image2: require("./../assets/images/section3_img2.png"),
  },
  {
    title1: 'English = Confidence',
    title2: 'Wellness = Success ',
    text1: 'Boost confidence through English language proficiency, owing to its significance in the tech industry.',
    text2: 'Importance of wellness and mental health n achieving success in Tech industry',
    image1: require("./../assets/images/section3_img1.png"),
    image2: require("./../assets/images/section3_img2.png"),
  },
];

const item1 = [
  {
    text: 'Are you ready tests for understanding self',
    image1: require("./../assets/images/section4_img1.png"),
    position: 'top',
  },
  {
    text: 'Tech for sustenance- Knowledge and awareness for getting started in learning technology for employability, employment and entrepreneurship',
    image1: require("./../assets/images/section4_img2.png"),
    position: 'bottom',
  },
  {
    text: 'Life skills - Finance,Wellbeing, Personality development,tech career counselling sessions and English',
    image1: require("./../assets/images/section4_img1.png"),
    position: 'top',
  },
  {
    text: 'Learning from industry leaders experiences and an opportunity to understand the job roles and trends',
    image1: require("./../assets/images/section4_img2.png"),
    position: 'bottom',
  },
  {
    text: 'Workshops, webinars, podcasts, C.L.A.P s for self development',
    image1: require("./../assets/images/section4_img1.png"),
    position: 'top',
  },
];

const item2 = [
  {
    text: 'For Industries - a channel to show solidarity for inclusive development understanding the views and aspirations of talent with gender identities and abilities',
    image1: require("./../assets/images/section4_img1.png"),
    position: 'top',
  },
  {
    text: 'For individual coaches and trainers – a channel to position their programs and provide them for the interested aspirants',
    image1: require("./../assets/images/section4_img2.png"),
    position: 'bottom',
  },
  {
    text: 'For NGOs – a place to share their mission, and connect with industries, coaches for synergy',
    image1: require("./../assets/images/section4_img1.png"),
    position: 'top',
  },
  {
    text: 'For Government to position the policies and nudge the stakeholders for adoption',
    image1: require("./../assets/images/section4_img2.png"),
    position: 'bottom',
  },
  {
    text: 'For Advocacy champions - to influence inclusive development',
    image1: require("./../assets/images/section4_img1.png"),
    position: 'top',
  },
];

function IndexPage(props) {
  const [activeSection, setActiveSection] = useState("home");

  const scroll = () => {
    const homeSection = document.getElementById("home");
    const aboutSection = document.getElementById("about");
    const contactSection = document.getElementById("pillar");
    const reasonSection = document.getElementById("reason");
    const registerSection = document.getElementById("register");
    if (
      (window.pageYOffset >= homeSection.offsetTop &&
      window.pageYOffset+300  < aboutSection.offsetTop) || window.pageYOffset === 0
    ) {
      setActiveSection("home");
    } else if (
      window.pageYOffset+300 >= aboutSection.offsetTop &&
      window.pageYOffset+300 < contactSection.offsetTop
    ) {
      setActiveSection("about");
    } 
    else if (
      window.pageYOffset+300 >= contactSection.offsetTop &&
      window.pageYOffset+300 < reasonSection.offsetTop
    ) {
      setActiveSection("pillar");
    }
    else if (
      window.pageYOffset+300 >= aboutSection.offsetTop &&
      window.pageYOffset+300 < registerSection.offsetTop
    ) {
      setActiveSection("reason");
    }else {
      setActiveSection("register");
    }
  };
  React.useEffect(() => {
    window.addEventListener("scroll", scroll);
    return () => {
      window.removeEventListener("scroll", scroll);
    };
  
  }, []);
  return (
    <>
      <Meta />
      <Navbar
            activeSection={activeSection}
            color="default"
            logo={require("./../assets/images/hcl_logo.png")}
            second_logo={require("./../assets/images/equity_logo.png")}
          />
      <section id='home'style={{marginTop:'65px'}}>
      <HeroSection
        bgColor="default"
        size="auto"
        bgImage=""
        right_bg_image={require("./../assets/images/section1_world.png")}
        right_image={require("./../assets/images/section1_right.png")}
        bgImageOpacity={1}
        title="Your landing page title here"
        subtitle="This landing page is perfect for showing off your awesome product and driving people to sign up for a paid plan."
        image={require("./../assets/images/section1_logo.png")}
        buttonText="Get Started"
        buttonColor="primary"
        buttonPath="/pricing"
        left_content= {<span>An Advocacy Platform For Inclusive Learning</span>}
      />
      </section>
      <hr style={{width: '100%', height: 5, color: '#493088', zIndex: 999,  backgroundColor: '#493088'}} />
      <section id='about'>
      <FeaturesSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="About Equity"
        section_left_content="A forum to connect and propel inclusive learning"
        backgroundimg={require("./../assets/images/section2_background.png")}
        description={<span>The future of work is changing rapidly, and as per World Economic Forum, over 1 billion jobs are liable to be radically  transformed by technology in this decade. While the Industries,  Institutions and Individuals embark on their mission to learn  tech skills, the import­ance of investing and providing inclusive learning for people with gender identities, abilities and women from non urban locations is the need of the hour.
          <br /> <br />
          eQuITy, powered by Career Shaper, is a resource center for various awareness programs on Technology, Finance, Business English, Wellbeing, Self Development and Career Counselling.
          <br />  <br />         
          Though eQuITy is a nudge, we believe it will become a community of learners and providers. While the providers contribute knowledge and resources, the aspirants  can start­ or continue their tech or entrepreneurial journey with support­ from these providers.
          <br />   <br />        
          We are looking for pa­rtners, individuals or institutions to become providers.
          <br />   <br />        
          Join eQuITy today and be part­ of a community which believes in inclusive development.</span>}
        image={require("./../assets/images/section2_left.png")}
      />
      </section>
      
      <section id='pillar' >
      <ContentCardsSection
        bgColor="default"
        size="medium"
        bgImage={require("./../assets/images/bg2.png")}
        bgImageOpacity={0.3}
        title="Pillars of EQUITY"
        subtitle=""
        item={items}
      />
      </section>
      <section id='reason'>
            <CardsSection
        bgColor="default"
        size="medium"
        bgImage={require("./../assets/images/bg3.png")}
        bgImageOpacity={0.3}
        title="What do I get?"
        subtitle="For Organizations and Institutions"
        item={item1}
      />
   <CardsSection
        bgColor="default"
        size="medium"
        bgImage={require("./../assets/images/bg2.png")}
        bgImageOpacity={0.3}
        title=""
        subtitle="For Learning Providers"
        item={item2}
      />
      </section>
      <section id='register'>
      <ContactSection
        bgColor="default"
        size="medium"
        bgImage=''
        bgImageOpacity={1}
        title=""
        subtitle=""
        orgtype={orgtype}
        form_heading="Join Our Growing Community Today"
        buttonText="Register Now"
        buttonColor="primary"
        showNameField={true}
      />
      </section>
    </>
  );
}

export default IndexPage;
