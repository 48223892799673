import React from "react";
import IndexPage from "./index";
import { Switch, Route, Router } from "./../util/router";
import NotFoundPage from "./404";
import Footer from "./../components/Footer";
import "./../util/analytics";
import { ThemeProvider } from "./../util/theme";
import './../index.css';

function App(props) {
  return (
    <ThemeProvider>
      <Router>
        <>


          <Switch>
            <Route exact path="/" component={IndexPage} />

            <Route component={NotFoundPage} />
          </Switch>

          <Footer
            bgColor="light"
            size="small1"
            bgImage={require("./../assets/images/footer_img.png")}
            bgImageOpacity={1}
            description={<span>For more details contact us at <a href='mailto:equity@career-shaper.com' style={{color: 'white'}}> equity@career-shaper.com</a></span>}
            description1={<span><a href='https://www.hcltech.com/privacy-statement' target='_blank' style={{color: 'white'}}>Privacy Statement | HCLTech</a></span>}
            sticky={true}
          />
        </>
      </Router>
    </ThemeProvider>
  );
}

export default App;
