import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Link } from "./../util/router";
import Box from "@material-ui/core/Box";
import Aos from 'aos';
import "aos/dist/aos.css";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 160,
  },
  image1: {
    backgroundRepeat: 'no-repeat',
    alignItems: "center",
    justifyContent: "center",
    height: '250px',
    width: '250px',
    backgroundSize: '250px 250px',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '40px',
    paddingRight: '40px',
    textAlign: 'center',
    marginTop: '0px',
    [theme.breakpoints.up("md")]: {
      height: '260px',
      width: '260px',
      backgroundSize: '260px 260px',
      marginTop: '100px',
    }
  },
    image2: {
      backgroundRepeat: 'no-repeat',
      alignItems: "center",
      justifyContent: "center",
      height: '250px',
      width: '250px',
      backgroundSize: '250px 250px',
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: '40px',
      paddingRight: '40px',
      textAlign: 'center',
      [theme.breakpoints.up("md")]: {
        height: '260px',
        width: '260px',
        backgroundSize: '260px 260px',
      }},

      title: {
        color: '#fff',
        fontSize: '13px',
        paddingLeft: '22px',
        paddingRight: '22px',
        [theme.breakpoints.up("md")]: {
          fontSize: '15px',
          paddingLeft: '18px',
          paddingRight: '18px',
        }},

      subtitle: {
        color: '#fff',
        fontSize: '13px',
        [theme.breakpoints.up("md")]: {
          fontSize: '12px',
          paddingLeft: '18px',
          paddingRight: '18px',
        }},

}));

function ContentCardsSection(props) {
  const classes = useStyles();


  React.useEffect(() => {
    Aos.init({ duration: 2000 });
}, [])
  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={5}
          textAlign="center"
        />
        <Grid container={true}  spacing={4}>
          {props.item.map((item, index) => (
            <>
                <Grid item={true} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}  xs={12} md={3}>
                <section data-aos="fade-up" data-aos-duration="1200">
                <Box sx={{ backgroundImage: `url(${item.image1})` }} className={classes.image1}>

                 <Typography variant="h5" className={classes.title} >
                  {item.title1}
                  <br />
                  <br />
                 </Typography>
                 <Typography variant="subtitle" className={classes.subtitle} >
                  {item.text1}
                 </Typography>
                </Box>
                </section>
                </Grid>
                <Grid item={true} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}  xs={12} md={3}>
                <section data-aos="fade-up" data-aos-duration="1800">
                <Box sx={{ backgroundImage: `url(${item.image2})` }} className={classes.image2}>

                 <Typography variant="h5"  className={classes.title}>
                  {item.title2}
                  <br />
                  <br />
                  
                 </Typography>
                 <Typography variant="subtitle"  className={classes.subtitle} >
                  {item.text2}
                
                 
                  
                 </Typography>
                </Box>
                </section>
                </Grid>
                </>
          ))}
        </Grid>
      </Container>
    </Section>
  );
}

export default ContentCardsSection;
