import React from "react";
import Container from "@material-ui/core/Container";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Contact from "./Contact";

function ContactSection(props) {
  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container  style={{boxShadow: "0px 4px 27px rgba(0, 0, 0, 0.25)", paddingRight: '0px'}}>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <Contact
          orgtype={props.orgtype}
          showNameField={props.showNameField}
          buttonText={props.buttonText}
          buttonColor={props.buttonColor}
          form_heading={props.form_heading}
        />
       
      </Container>
    </Section>
  );
}

export default ContactSection;
