import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Button from "@material-ui/core/Button";
import NightsStayIcon from "@material-ui/icons/NightsStay";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import { Link } from "./../util/router";
import { useDarkMode } from "./../util/theme";

const useStyles = makeStyles((theme) => ({
  logo: {
    height: 20,

  },
  second_logo: {
    height: 28,
    marginLeft: theme.spacing(2),
  },
  drawerList: {
    width: 250,
  },
  spacer: {
    flexGrow: 1,
  },
  button:{
    marginRight:'25px',
    fontWeight:'600',
    fontFamily:'HCLTechRoobert-Regular',
  }
}));

function Navbar(props) {
  const classes = useStyles();
  const darkMode = useDarkMode();
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <>
    <Section bgColor={props.color} size="auto"  >
      <AppBar position="fixed" color="white" elevation={0} style={{boxShadow: '0px 2px 0px rgba(0, 0, 0, 0.13)'}}>
        <Container disableGutters={true}>
          <Toolbar >
          
              <img src={props.logo} alt="Logo" className={classes.logo} />
           
            <div className={classes.spacer} />

            <Hidden smDown={true} implementation="css" >
            <Button
                color={props.activeSection === "home" ? "primary" :"inherit" }
                href="/#home"
                className={classes.button}
              >
                Home
              </Button>
              <Button
                color={props.activeSection === "about" ? "primary" :"inherit" }
                href="/#about"
                className={classes.button}
              >
                About Equity
              </Button>
              <Button
               color={props.activeSection === "pillar" ? "primary" :"inherit" }
                href="/#pillar"
                className={classes.button}
              >
                Pillars of Equity
              </Button>
              <Button
                color={props.activeSection === "reason" ? "primary" :"inherit" }
                href="/#reason"
                className={classes.button}
              >
                What do I get?
              </Button>
              <Button
                color={props.activeSection === "register" ? "primary" :"inherit" }
                href="/#register"
                variant="outlined"
                className={classes.button}
              >
                Register Now
              </Button>
            </Hidden>
          
              <img src={props.second_logo} alt="Logo" className={classes.second_logo} />
      
            <Hidden mdUp={true} implementation="css">
              <IconButton
                onClick={() => {
                  setDrawerOpen(true);
                }}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <List
          className={classes.drawerList}
          onClick={() => setDrawerOpen(false)}
        >
          <ListItem
            button={true}
            component="a"
            href="/#home"
          >
            <ListItemText>Home</ListItemText>
          </ListItem>
          <ListItem
            button={true}
            component="a"
            href="/#about"
          >
            <ListItemText>About Equity</ListItemText>
          </ListItem>
          <ListItem
            button={true}
            component="a"
            href="/#pillar"
          >
            <ListItemText>Pillars of Equity</ListItemText>
          </ListItem>
          <ListItem
            button={true}
            component="a"
            href="/#reason"
          >
            <ListItemText>What do I get?</ListItemText>
          </ListItem>
          <ListItem
            button={true}
            component="a"
            href="/#register"
          >
            <ListItemText>Register Now</ListItemText>
          </ListItem>

        </List>
      </Drawer>
    </Section>

    </>
  );
}

export default Navbar;
