import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Link } from "./../util/router";
import { Typography } from "@material-ui/core";
import Aos from 'aos';
import "aos/dist/aos.css";

const useStyles = makeStyles((theme) => ({


  image: {
    maxWidth: 140,
    marginTop: "50px",
    height: "auto",
    width: "100%",
    alignItems: "center",
    marginBottom:'20px',
    [theme.breakpoints.up("md")]: {
      alignItems: "left",
      maxWidth: 240,
      marginTop: "0px",
    },
  },

  image_right: {
    height: "auto",
    maxHeight: 180,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },
  image_bg:{
    backgroundRepeat: 'no-repeat',
    height: '500px' ,
    objectFit: 'cover',
    display: "flex",
    alignItems: "center",

    justifyContent: "center",
        [theme.breakpoints.down("xs")]: {
          height: '250px' ,
          backgroundSize: "cover",
          backgroundPosition: "right center",
    },
  }
}));

function HeroSection(props) {
  const classes = useStyles();
  React.useEffect(() => {
    Aos.init({ duration: 2000 });
}, [])
  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container >
        <Grid container={true} alignItems="center" spacing={2}>
        <Grid item={true} xs={12} md={3}>
           <Box textAlign={{xs:"center", md:"left"}} sx={{width: '100%'}}>
              <img
              data-aos="fade-up" data-aos-duration="1200"
                src={props.image}
                alt="illustration"
                className={classes.image}
              />
            <Typography variant={'h5'}>
              {props.left_content}
            </Typography>
            </Box>
          </Grid>
          <Grid container={true} item={true} direction="column" xs={12} md={9}>
            <Box   
            className={classes.image_bg}
                     sx={{
                      backgroundImage: `url(${props.right_bg_image})`,

                    }}>

                <img
                data-aos="fade-up" data-aos-duration="1200"
                src={props.right_image}
                alt="illustration"
                className={classes.image_right}
              />
            </Box>
          </Grid>

        </Grid>

      </Container>

    </Section>
    
  );
}

export default HeroSection;
