import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Link } from "./../util/router";
import Box from "@material-ui/core/Box";
import Aos from 'aos';
import "aos/dist/aos.css";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 160,
  },
  image: {
    backgroundRepeat: 'no-repeat',
    alignItems: "center",
    justifyContent: "center",
    height: '220px',
    width: '170px',
    backgroundSize: '170px 220px',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '15px',
    paddingRight: '28px',
    textAlign: 'center',
    [theme.breakpoints.up("md")]: {
      height: '250px',
      width: '190px',
      backgroundSize: '190px 250px',
      paddingLeft: '20px',
      paddingRight: '30px',

    }
  },


}));

function CardsSection(props) {
  const classes = useStyles();

  React.useEffect(() => {
    Aos.init({ duration: 2000 });
}, [])

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={5}
          textAlign="center"
        />
        <Grid container={true} justifyContent="center" spacing={4}>
          {props.item.map((item, index) => (
            <>
                <Grid item={true} alignItems={'center'}  xs={6} md={2}>
                <section data-aos="fade-up" data-aos-duration="1800">
                <Box sx={{ backgroundImage: `url(${item.image1})`, marginTop: item.position === 'top' ? '0px' : '100px'}} className={classes.image}>

                 <Typography variant="h5"  style={{  color: 'black', fontSize: '13px'}}>
                  {item.text}

                  
                 </Typography>

                </Box>
                </section>
                </Grid>
                </>
          ))}
        </Grid>
      </Container>
    </Section>
  );
}

export default CardsSection;
