import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ChatIcon from "@material-ui/icons/Chat";
import VideogameAssetIcon from "@material-ui/icons/VideogameAsset";
import PetsIcon from "@material-ui/icons/Pets";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import Aos from 'aos';
import "aos/dist/aos.css";

const useStyles = makeStyles((theme) => ({

  container: {
    padding: `0 ${theme.spacing(4)}px`,
  },
  imageWrapper: {
    margin: "0 auto",
    maxWidth: 570,
    width: "100%",
    "& > img": {
      width: "100%",
    },
  },
  row: {
    // Spacing between rows
    "&:not(:last-child)": {
      marginBottom: `${theme.spacing(2)}px`,
    },
  },
  bgimg: {
    width: "100%",
    zIndex:999,
    [theme.breakpoints.up("md") && theme.breakpoints.down("lg")]: {
      marginTop: "-420px",
      marginBottom: "-80px",
    },
    [theme.breakpoints.up("lg") ]: {
      marginTop: "-410px",
      marginBottom: "-60px",
    },
    [theme.breakpoints.up("sm") && theme.breakpoints.down("md")]: {
      marginTop: "-250px",
      marginBottom: "-80px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "-50px",
      marginBottom: "-50px",
    },
  },
}));

function FeaturesSection(props) {
  const classes = useStyles();
  React.useEffect(() => {
    Aos.init({ duration: 2000 });
}, [])

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className={classes.container}>
        <Grid container={true} alignItems="center" spacing={8} >
          <Grid container={true} item={true} direction="column" xs={12} md={6} style={{display: 'flex', alignItems:'center', justifyContent: 'center'}} >
            <figure  className={classes.imageWrapper}>
              <img data-aos="fade-up" data-aos-duration="1200" src={props.image} alt="" />
            </figure>

          
            <Box  sx={{boxShadow: '6px 7px 17px rgba(0, 0, 0, 0.25)', maxWidth: '300px', borderRadius: "25px", textAlign: "center", marginTop:'20px' }}>
              <Typography variant="h5" gutterBottom={true} style={{padding:20}}>
                {props.section_left_content}
              </Typography>
            </Box>
           
          </Grid>
          <Grid item={true} xs={12} md={6}>
          <section data-aos="fade-up" data-aos-duration="1200">

                  <Typography variant="h5" gutterBottom={true}>
                    {props.title}
                  </Typography>
                  <Typography variant="subtitle1">
                    {props.description}
                  </Typography>

                  </section>
          </Grid>
        </Grid>
      </Container>
      <img src={props.backgroundimg}  className={classes.bgimg}  />

    </Section>
  );
}

export default FeaturesSection;
